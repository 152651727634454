
export default class Socket {
  constructor(options) {
    this.options = options
    this.init(options.code)
  }

  init(code) {
    let sensorType = this.options.sensorType
    let baseUrl = `wss://iot.shmashine.com/websocket/ws/Monitor/${sensorType}`
    this.websocket = new WebSocket(`${baseUrl}/${code}`)
    this.websocket.onopen = () => {
      console.log('socket is open')
      this.options.onopen && this.options.onopen(true)
    }
    this.websocket.onclose = (e) => {
      console.log('connection closed (' + e.code + ')')
      this.options.onclose && this.options.onclose(e)
    }
    this.websocket.onerror = (e) => {
      console.log('error (' + e.code + ')')
      this.options.onerror && this.options.onerror(e)
    }
    this.websocket.onmessage = ({data}) => {
      this.options.onchange && this.options.onchange(JSON.parse(data))
    }
  }

  send(data) { //数据发送
    console.log('send')
    this.websocket.send(data)
  }

  close() { //关闭
    console.log('close')
    this.websocket.close()
  }
}
