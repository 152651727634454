import http from '@/common/utils/http'

export function getVillageList(data = {}) {
  return http({
    url: "pm/village/searchVillageList",
    method: 'post',
    data
  })
}

export function getElevatorList(data = {}) {
  return http({
    url: "elevator/searchElevatorByUserId",
    method: 'post',
    data
  })
}

export function getElevatorLiveUrl(data = {}) {
  return http({
    url: `camera/${data.elevatorId}`,
    method: 'get'
  })
}

/** 获取电梯监控画面Url TOKEN  */
export function getElevatorMonitorUrlEzopenToken (data = {}) {
    return http({
        url: `camera/ezopenToken`,
        method: 'get',
        data
    })
} 